import { inputType } from './Register';
export class Control {
    // tslint:disable-next-line:no-any
    value: any;
    error: string | undefined;
    // tslint:disable-next-line:no-any
    isValid: (val: any) => string;
    touched: boolean;
    label: string;
    placeholder: string;
    type: inputType;
    constructor(
        label: string,
        placeholder: string,
        // tslint:disable-next-line:no-any
        isValid: (val: any) => string,
        type: inputType = 'text') {
        this.isValid = isValid;
        this.value = '';
        this.touched = false;
        this.label = label;
        this.placeholder = placeholder;
        this.type = type;
    }
}
