import * as React from 'react';
import { Table } from 'reactstrap';
import { ArchiveAthlete } from '../BrevetSvc';
import { Link } from 'react-router-dom';

export default class ArchiveRandonnerBrevetsTable extends React.Component<{ athlete: ArchiveAthlete }, {}> {
    render() {
        return (
            <Table hover={true}>
                <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Бревет</th>
                        <th>Дистанция</th>
                        <th>Город</th>
                        <th>Клуб</th>
                        <th>Вел</th>
                        <th>Результат</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.athlete.brevets &&
                        this.props.athlete.brevets.map((res, i) => (
                            <tr key={i}>
                                <td>{res.date ? new Date(res.date).toLocaleDateString('ru') : ''}</td>
                                <td><Link to={`/archive/${new Date(res.date).getFullYear()}/${res.brevetId}`}>
                                    {res.name}</Link></td>
                                <td>{res.distance}</td>
                                <td>{res.city}</td>
                                <td>{res.club}</td>
                                <td>{res.bikeType}</td>
                                <td>{res.totalTime}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        );
    }
}
