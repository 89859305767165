import * as React from 'react';
import { Table } from 'reactstrap';
import { SeasonResult, SeasonBrevetResult } from '../BrevetSvc';
import { Link } from 'react-router-dom';

export default class SeasonResultTable extends React.Component<{ results: SeasonResult[] }, {}> {
    link(r: SeasonBrevetResult, ind: number) {
        return (
            <div key={ind}>
                <Link className="text-dark text-underlined"
                    key={ind} title={r.brevetName} to={`/${r.brevetId}`}><u>{r.result}</u></Link>
            </div>
        );
    }
    render() {
        let points = 0;
        return (
            <Table hover={true}>
                <thead>
                    <tr>
                        <th />
                        <th>Фамилия</th>
                        <th>Имя</th>
                        <th>Г.р.</th>
                        <th>Город</th>
                        <th>Клуб</th>
                        <th>200</th>
                        <th>300</th>
                        <th>400</th>
                        <th>600</th>
                        <th>1000+</th>
                        <th>Очки</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.results.map((res, i) => (
                        <tr key={i}>{((points += res.points) && null) || null}
                            <td>{i + 1}</td>
                            <td>{res.lastName}</td>
                            <td>{res.firstName}</td>
                            <td>{res.year}</td>
                            <td>{res.cities.join(', ')}</td>
                            <td>{res.clubs.join(', ')}</td>
                            <td>{res.twos.map(this.link)}</td>
                            <td>{res.threes.map(this.link)}</td>
                            <td>{res.fours.map(this.link)}</td>
                            <td>{res.sixes.map(this.link)}</td>
                            <td>{res.thousands.map(this.link)}</td>
                            <td className="text-right">{res.points}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th />
                        <th />
                        <th />
                        <th />
                        <th />
                        <th />
                        <th />
                        <th />
                        <th />
                        <th />
                        <th>{points}</th>
                    </tr>
                </tfoot>
            </Table>
        );
    }
}
