import React from 'react';
import { Alert } from 'reactstrap';

// tslint:disable-next-line:no-any
class NetworkError extends React.Component<{ error: any, message?: string }, {}> {
    render() {

        const msg = this.props.message || 'Ошибка получения данных бревета:';
        return (
            <Alert color="danger">
                <span>
                    {msg + ' '}
                    {(this.props.error && (this.props.error.message || JSON.stringify(this.props.error))) || ''}
                </span>
            </Alert>
        );
    }
}

export default NetworkError;