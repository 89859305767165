import React from 'react';
import BrevetSvc, { ArchiveBrevet } from '../BrevetSvc';
import * as Sentry from '@sentry/browser';
import {
    Row, Col, DropdownToggle, DropdownMenu, Nav, NavItem,
    NavLink, TabContent, TabPane, Dropdown, DropdownItem
} from 'reactstrap';
import LoadingIndicator from '../LoadingIndicator';
import NetworkError from '../NetworkError';
import { RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';
import ArchiveBrevetsList from './ArchiveBrevetsList';
import ArchiveBrevetsTable from './ArchiveBrevetsTable';
import ArchiveRandonnersTable from './ArchiveRandonnersTable';
import ViewSelector from '../ViewSelector';
import ArchiveRandonnersList from './ArchiveRandonnersList';
import moment from 'moment';

const InitialState = {
    activeTab: 1,
    allYears: undefined as number[] | undefined,
    year: -1,
    isLoading: true,
    dropdownOpen: false,
    error: undefined as string | undefined,
    brevets: undefined as ArchiveBrevet[] | undefined
};
type Props = Readonly<RouteComponentProps<{ year?: string }>>;
// type RandonnersComponent = { randonners: ArchiveRandonner[] };

export default class ArchiveYearView extends React.Component<Props, typeof InitialState> {
    mounted = false;

    constructor(props: Props) {
        super(props);
        let year = ArchiveYearView.parseYear(props)
        let state = { ...InitialState, year };
        if (props.location.pathname.endsWith('/a')) state.activeTab = 2;
        this.state = state;
        this.toggle = this.toggle.bind(this);
    }

    private log(s: string) {
        // console.log(s)
    }

    static parseYear(props: Props) {
        return !props.match.params.year || props.match.params.year === 'all' ?
            0 : parseInt(props.match.params.year, 10);
    }

    loadAllYears() {
        this.setState({isLoading: true})
        BrevetSvc.GetArchiveAllYears().then(
            result => {
                if (!this.mounted) return;
                document.title = `Архив всех сезонов ${process.env.REACT_APP_CLUBNAME || 'КБП'}`;
                this.setState({
                    brevets: result.brevets,
                    error: undefined,
                    isLoading: false,
                });
            },
            error => {
                if (!this.mounted) return;
                Sentry.captureException(error);
                this.setState({ error: error, isLoading: false });
            }
        );
        return false;
    }

    loadYear(year: number) {
        this.setState({isLoading: true})
        BrevetSvc.GetArchiveByYear(year).then(
            result => {
                if (!this.mounted) return;
                document.title = `Архив сезона ${result.year || ''} ${process.env.REACT_APP_CLUBNAME || 'КБП'}`;
                this.setState({
                    brevets: result.brevets,
                    error: undefined,
                    isLoading: false,
                    year: year
                });
            },
            error => {
                if (!this.mounted) return;
                Sentry.captureException(error);
                this.setState({ error: error, isLoading: false });
            }
        );
        return false;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
        BrevetSvc.GetArchive().then(results => {
            this.setState({ allYears: results.allyears });
        });
        this.loadData()
    }
    
    loadData() {
        this.log(`loadData ${this.state.year}`)
        if (this.state.year) this.loadYear(this.state.year);
        else this.loadAllYears();
    }
    
    toggle() {
        this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
        this.log(`toggle: ${this.state.dropdownOpen}`)
    }
    
    openYear = (e: React.MouseEvent<HTMLElement>) => {
        let year = (e.target as HTMLElement).dataset['year'];
        if (year) {
            if (year === 'all')
                this.setState({year: 0})
            else {
                let yearInt = parseInt(year)
                if (yearInt > 0)
                    this.setState({year: yearInt})
            }
            this.props.history.push(`/archive/${year}`)
        }
    }
    
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<typeof InitialState>) {
        this.log(`componentDidUpdate prev:${prevState.year} new: ${this.state.year}`)
        if (prevState.year !== this.state.year) this.loadData()
    }

    render() {
        return (
            <Row><Col>
                <h1>Aрхив сезона
                    {this.state.allYears &&
                        (<Dropdown isOpen={this.state.dropdownOpen}
                            toggle={this.toggle}
                            className="d-inline-block ms-3">
                            <DropdownToggle caret={true}>{this.state.year || 'Все'}</DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    key="_"
                                    data-year='all'
                                    onClick={this.openYear}>Все</DropdownItem>
                                {this.state.allYears.map(y =>
                                    <DropdownItem
                                        key={y}
                                        data-year={y}
                                        onClick={this.openYear}>
                                        {y}
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                        )}
                </h1>
                {this.state.isLoading && <LoadingIndicator />}
                {this.state.error && <NetworkError error={this.state.error} />}
                {this.state.brevets &&
                    (<div>
                        <Nav tabs={true} fill={true}>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === 1 })}
                                    onClick={(e) => { this.setState({ activeTab: 1 }); e.stopPropagation(); }}>
                                    Бреветы
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === 2 })}
                                    onClick={(e) => { this.setState({ activeTab: 2 }); e.stopPropagation(); }}>
                                    Рандоннеры
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId={1}>
                                <ViewSelector<{brevets: ArchiveBrevet[]}>
                                    brevets={this.state.brevets}
                                    MobileComponent={ArchiveBrevetsList}
                                    Component={ArchiveBrevetsTable}
                                />
                            </TabPane>
                            <TabPane tabId={2}>
                                <ViewSelector<{randonners: ArchiveRandonner[]}>
                                    randonners={this.buildRandonners(this.state.brevets)}
                                    Component={ArchiveRandonnersTable}
                                    MobileComponent={ArchiveRandonnersList}
                                />
                            </TabPane>
                        </TabContent>
                    </div>)
                }
            </Col></Row>
        );
    }

    private buildRandonners(brevets: ArchiveBrevet[]): ArchiveRandonner[] {
        let result: ArchiveRandonner[] = [];
        brevets.forEach((brevet) => {
            brevet.results.forEach(res => {
                let rand = result[res.athleteId];
                if (rand) {
                    // update athlete values
                    if (res.city && rand.city.indexOf(res.city) < 0) {
                        if (rand.city.length) rand.city += ', ';
                        rand.city += res.city;
                    }
                    if (res.club && rand.club.indexOf(res.club) < 0) {
                        if (rand.club.length) rand.club += ', ';
                        rand.club += res.club;
                    }
                } else {
                    result[res.athleteId] = rand = {
                        id: res.athleteId,
                        city: res.city || '',
                        club: res.club || '',
                        fours: [],
                        name: res.name || '!!Неизвестный!!',
                        sixes: [],
                        points: 0,
                        thousands: [],
                        thousandTwoHundreds: [],
                        threes: [],
                        twos: [],
                        yearOfBirth: res.yearOfBirth
                    };
                }
                if (brevet.distance <= 200) rand.twos.push(toRandonnerResult(brevet, res.totalTime));
                else if (brevet.distance <= 300) {
                    rand.threes.push(toRandonnerResult(brevet, res.totalTime));
                } else if (brevet.distance <= 400) {
                    rand.fours.push(toRandonnerResult(brevet, res.totalTime));
                } else if (brevet.distance <= 600) {
                    rand.sixes.push(toRandonnerResult(brevet, res.totalTime));
                } else if (brevet.distance <= 1000) {
                    rand.thousands.push(toRandonnerResult(brevet, res.totalTime));
                } else rand.thousandTwoHundreds.push(toRandonnerResult(brevet, res.totalTime));

                if (res.totalTime) rand.points += Math.floor(brevet.distance / 100);
            });
        });
        result.sort((a, b) => b.points - a.points || a.name.localeCompare(b.name));
        return result;
    }
}

function toRandonnerResult(brevet: ArchiveBrevet, totalTime?: string): ArchiveRandonnerResult {
    return {
        brevetId: brevet.brevetId,
        brevetName: brevet.name,
        year: moment(brevet.date).year(),
        date: brevet.date,
        totalTime
    };
}

export interface ArchiveRandonner {
    id: number;
    name: string;
    yearOfBirth: number;
    city: string;
    club: string;
    twos: ArchiveRandonnerResult[];
    threes: ArchiveRandonnerResult[];
    fours: ArchiveRandonnerResult[];
    sixes: ArchiveRandonnerResult[];
    thousands: ArchiveRandonnerResult[];
    thousandTwoHundreds: ArchiveRandonnerResult[];
    points: number;
}

export interface ArchiveRandonnerResult {
    brevetId: number;
    brevetName: string;
    year: number;
    date: string;
    totalTime?: string;
}