import moment, {Moment} from 'moment';
import {BrevetInfoCheckPoint} from '../BrevetSvc';

export function calcDist(lat1: number, lon1: number, lat2: number, lon2: number) {
    // Source: http://www.movable-type.co.uk/scripts/latlong.html
    const R = 6371e3; // metres
    const φ1 = toRadians(lat1);
    const φ2 = toRadians(lat2);
    const Δφ = toRadians(lat2 - lat1);
    const Δλ = toRadians(lon2 - lon1);

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
}

function toRadians(degr: number) {
    return Math.PI / 180 * degr;
}

export function formatDistance(dist: number) {
    if (dist > 2000) {
        return Math.round(dist / 1000) + ' км';
    }
    return Math.round(dist) + ' м';
}

export function formatTime(time?: Moment | null) {
    if (!time) return '';
    return time.isSame(moment(), 'day') ?
        time.format('H:mm') :
        time.format('H:mm D MMM');
}

export function getDiff(date1: Date, date2: Date, addPlus: boolean = false): string {
    let total = moment.duration(moment(date1).diff(date2));
    let result = total.asSeconds() < 0 ? '-' : addPlus ? '+' : ''; 
    total = total.abs();
    result += Math.floor(total.asHours()) + moment.utc(total.asMilliseconds()).format(':mm:ss');
    return result;
}

export function calcCheckPointTimes(checkpoint: BrevetInfoCheckPoint, startTime: Date | null | undefined) {
    let openingTime = null, closingTime = null, gap = null;
    if (startTime && (checkpoint.openingTime || checkpoint.closingTime)) {
        openingTime = moment(startTime)
            .add(moment.duration(checkpoint.openingTime));
        closingTime = moment(startTime)
            .add(moment.duration(checkpoint.closingTime));

        let dif = moment.duration(closingTime.diff(moment()));
        gap = Math.floor(dif.asHours()) + moment.utc(dif.asMilliseconds()).format(':mm');
        if (dif.asMinutes() > 0) {
            gap = '+' + gap;
        }
    }
    return { openingTime, closingTime, gap }
}

export function getMapUrl(checkpoint: BrevetInfoCheckPoint) : string {
    const isAndroid = navigator && navigator.userAgent && navigator.userAgent.match(/Android/i);
    const isApple = navigator && navigator.userAgent && navigator.userAgent.match(/iPhone/i);
    const lat = checkpoint.latitude, lon = checkpoint.longitude;
    return isAndroid || isApple ?
        `${isApple ? 'maps' : 'geo'}:${lat},${lon}?z=16&q=${lat},${lon}(${checkpoint.name})` :
        `https://yandex.ru/maps/?ll=${lon},${lat}&z=16&pt=${lon},${lat},comma`;
}