import * as React from 'react';
import { Table } from 'reactstrap';
import { ArchiveBrevet } from '../BrevetSvc';
import { Link } from 'react-router-dom';

export default class ArchiveBrevetsResultTable extends React.Component<{ brevet: ArchiveBrevet }, {}> {
    render() {
        return (
            <Table hover={true}>
                <thead>
                    <tr>
                        <th />
                        <th>Имя</th>
                        <th>Г.р.</th>
                        <th>Город</th>
                        <th>Клуб</th>
                        <th>Вел.</th>
                        {this.props.brevet.cps &&
                            this.props.brevet.cps.map((cp, i) => (
                                <th key={i}>{cp}</th>
                            ))}
                        <th>Общее время</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.brevet.results &&
                        this.props.brevet.results.map((res, i) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td><Link to={`/archive/a/${res.athleteId}`}>{res.name}</Link></td>
                                <td>{res.yearOfBirth}</td>
                                <td>{res.city}</td>
                                <td>{res.club}</td>
                                <td>{res.bikeType}</td>
                                {this.props.brevet.cps.map((_cp, ind) => {
                                    let cp = res.cps[ind];
                                    if (cp) {
                                        let dates = new Date(cp).toLocaleTimeString('ru').split(':');
                                        cp = `${dates[0]}:${dates[1]}`;
                                    } else cp = '';
                                    return (<td key={ind}>{cp}</td>);
                                })}
                                <td>{res.totalTime}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        );
    }
}
