import * as React from 'react';
import { ArchiveBrevet } from '../BrevetSvc';
import { ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { Route } from 'react-router-dom';

interface Props { brevets: ArchiveBrevet[]; }

const ArchiveBrevetsList: React.SFC<Props> = (props) => {
    return (
        <Route render={({ history }) =>
            <ListGroup>
                {props.brevets && props.brevets.map((res, ind) =>
                    <ListGroupItem key={ind} action={true}
                        onClick={() =>
                            history.push(`/archive/${new Date(res.date).getFullYear()}/${res.brevetId}`)}>
                        <ListGroupItemHeading>{res.name}</ListGroupItemHeading>
                        <p>{new Date(res.date).toLocaleDateString('ru')} {res.city} {res.distance}км</p>
                    </ListGroupItem>
                )}
            </ListGroup >}
        />);
};
export default ArchiveBrevetsList;