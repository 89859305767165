import * as React from 'react';
import { Table } from 'reactstrap';
import { ArchiveRandonner, ArchiveRandonnerResult } from './ArchiveYearView';
import { Link } from 'react-router-dom';

interface Props { randonners: ArchiveRandonner[]; }

const ArchiveRandonnersTable: React.SFC<Props> = (props) => {
    let totalPoints = 0;
    return (
        <Table hover={true}>
            <thead>
                <tr>
                    <th>Имя</th>
                    <th>Г.р.</th>
                    <th>Город</th>
                    <th>Клуб</th>
                    <th>200</th>
                    <th>300</th>
                    <th>400</th>
                    <th>600</th>
                    <th>1000</th>
                    <th>1200</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {props.randonners.map((res, i) => (
                    <tr key={i}>
                        <td><Link to={`/archive/a/${res.id}`}>{res.name}</Link></td>
                        <td>{res.yearOfBirth}</td>
                        <td>{res.city}</td>
                        <td>{res.club}</td>
                        <td>{res.twos.map(link)}</td>
                        <td>{res.threes.map(link)}</td>
                        <td>{res.fours.map(link)}</td>
                        <td>{res.sixes.map(link)}</td>
                        <td>{res.thousands.map(link)}</td>
                        <td>{res.thousandTwoHundreds.map(link)}</td>
                        <td>{((totalPoints += res.points) || true) && res.points}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>{totalPoints}</td>
                </tr>
            </tfoot>
        </Table>
    );

    function link(result: ArchiveRandonnerResult) {
        return (
            <Link
                to={`/archive/${result.year}/${result.brevetId}`}
                title={`${result.brevetName} ${result.year}`}
                className="px-1 d-block"
                key={result.brevetId}
            >
                {result.totalTime || 'dnf'}
            </Link >
        );
    }
};

export default ArchiveRandonnersTable;