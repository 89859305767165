import * as React from 'react';

const spinnerDelay = 200;
const timeout = 10000;

// tslint:disable-next-line:no-any
type Payload = any;
export type ImportFunction = () => Promise<Payload>;

export default function makeAsyncComponent<ComponentProps>(
    loader: () => Promise<Payload>,
    loadingComponent?: () => JSX.Element
) {
    let loadingState: LoadingState | undefined = undefined;

    return class AsyncComponent extends React.Component<ComponentProps, {
        error: Error | undefined;
        pastSpinnerDelay: boolean;
        timedOut: boolean;
        loading: boolean;
        payload: Payload;
    }> {
        _mounted = false;
        // tslint:disable-next-line:no-any
        _spinnerDelay: any = undefined;
        // tslint:disable-next-line:no-any
        _timeout: any = undefined;

        // tslint:disable-next-line:no-any
        constructor(props: any) {
            super(props);

            if (!loadingState) {
                loadingState = (function asyncLoad(importFunction: ImportFunction) {
                    let state: LoadingState = {
                        loading: true,
                        payload: undefined,
                        error: undefined,
                        promise: importFunction()
                        .then(payload => {
                            state.loading = false;
                            state.payload = payload;
                            return payload;
                        })
                        .catch(err => {
                            state.loading = false;
                            state.error = err;
                            throw err;
                        })
                    };

                    return state;
                })(loader);
            }

            this.state = {
                error: loadingState.error,
                pastSpinnerDelay: false,
                timedOut: false,
                loading: loadingState.loading,
                payload: loadingState.payload
            };
        }

        componentDidMount() {
            this._mounted = true;

            // wait to show loading indicator
            this._spinnerDelay = setTimeout(
                () => {
                    this.setState({ pastSpinnerDelay: true });
                },
                spinnerDelay);

            // timeout
            this._timeout = setTimeout(
                () => {
                    this.setState({ timedOut: true });
                },
                timeout
            );

            let update = () => {
                if (!this._mounted) {
                    return;
                }

                if (loadingState) {
                    this.setState({
                        error: loadingState.error,
                        payload: loadingState.payload,
                        loading: loadingState.loading
                    });
                }
            };
            if (loadingState) {
                loadingState.promise
                    .then(() => {
                        update();
                    })
                    .catch(err => {
                        update();
                        throw err;
                    });
            }
        }

        componentWillUnmount() {
            this._mounted = false;
            if (this._spinnerDelay) {
                clearTimeout(this._spinnerDelay);
            }

            if (this._timeout) {
                clearTimeout(this._timeout);
            }
        }

        render() {
            if (this.state.loading) {
                if (this.state.timedOut) {
                    return <div> Error! Component failed to load by timeout</div>;
                }
                if (this.state.pastSpinnerDelay && loadingComponent) {
                    return loadingComponent();
                }
                return null;
            } else if (this.state.error) {
                return <div>Error ! Component failed to load {this.state.error.toString()}</div>;
            } else if (this.state.payload) {
                let resolve = this.state.payload.__esModule ? this.state.payload.default : this.state.payload;
                return React.createElement(resolve, this.props);
            } else {
                return null;
            }
        }
    };
}

interface LoadingState {
    loading: boolean;
    payload: Payload | undefined;
    error: Error | undefined;
    promise: Promise<Payload>;
}