import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/startWith';

const site = process.env.REACT_APP_APIURL; 
const REG_STORAGE_KEY = 'BrevetRegistration';
const RUN_STORAGE_KEY = 'BrevetRun';
const RUN_STORAGE_REG_KEY = 'BrevetRunRes';
const RUN_STORAGE_PENDING_KEY = 'BrevetRunPending';
const BREVET_CACHE_STORAGE_KEY = 'BrevetInfo';

export default class BrevetSvc {
    private static readonly localStorageIsAvailable: boolean = (() =>
	    {
	    // check localStorage is available
	    let storage;
	    try {
		    storage = window['localStorage'];
		    const x = '__storage_test__';
		    storage.setItem(x, x);
		    storage.removeItem(x);
		    return true;
	    } catch (e) {
		    return (e instanceof DOMException && (
				    // everything except Firefox
				    e.code === 22 ||
				    // Firefox
				    e.code === 1014 ||
				    // test name field too, because code might not be present
				    // everything except Firefox
				    e.name === 'QuotaExceededError' ||
				    // Firefox
				    e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
			    // acknowledge QuotaExceededError only if there's something already stored
			    (storage && storage.length !== 0)) ||
			    false;
	    }
    })();
		
    static readonly testBrevet: BrevetInfo =
        {
            id: 1,
            city: 'Kursk',
            checkPoints: [
                { name: 'Старт', latitude: 51.7722, longitude: 36.1777, shortName: 'Старт' },
                { name: 'Шуклинка', latitude: 51.7948, longitude: 36.2024, shortName: 'КП1' },
                { name: 'Финиш', latitude: 51.7714, longitude: 36.1744, shortName: 'Финиш' }
            ],
            completed: false,
            finished: false,
            date: moment().startOf('day').toISOString(),
            name: 'Пробный',
            distance: 200,
            registered: true,
            isRunning: true
        };

    private static cacheCleaned = false;
    private static seasonResults: SeasonResult[];
    private static archive: ArchiveInfo;
    private static archiveResults: ArchiveYearResult[] = [];
    private static archiveYears: number[];
    private static archiveAthletes: ArchiveAthlete[] = [];

    static GetTestBrevet(): BrevetInfo {
        return { ...this.testBrevet };
    }

    static GetBrevets(): Observable<BrevetInfo[]> {
        var result = Observable.fromPromise(
            fetch(site + '/api/brevet')
                .then(this.handleErrors)
                .then(res => res.json())
                .then(brevets =>
                    this.PutBrevetsToCache(brevets.map((brevet: BrevetInfo) => BrevetSvc.setCalculatedProps(brevet)))
                ));
        var cached = this.GetBrevetsFromCache();
        return cached ? result.startWith(cached) : result;
    }

    static GetBrevet(id: number, cacheIsFine: boolean = false): Observable<BrevetInfo> {
        var cached = BrevetSvc.GetBrevetFromCache(id);
        if (cached && cacheIsFine) return Observable.of(cached);
        // init request
        var result = Observable.fromPromise(fetch(site + '/api/brevet/' + id)
            .then(this.handleErrors)
            .then(res => res.json())
            .then(this.setCalculatedProps)
            .then(this.PutBrevetToCache));
        // view in cache
        return cached ? result.startWith(cached) : result;
    }

    static GetSeason(): Promise<SeasonResult[]> {
        if (this.seasonResults) return Promise.resolve(this.seasonResults);
        return fetch(`${site}/api/brevet/season`)
            .then(this.handleErrors)
            .then(res => res.json())
            .then(results => this.seasonResults = results);
    }

    static GetArchive(): Promise<ArchiveInfo> {
        return this.archive ?
            Promise.resolve(this.archive) :
            fetch(`${site}/api/archive`)
                .then(this.handleErrors)
                .then(res => res.json())
                .then((res) => BrevetSvc.archive = res);
    }

    static GetArchiveAllYears(): Promise<ArchiveYearResult> {
        if (this.archiveResults[0]) {
            return Promise.resolve(this.archiveResults[0]);
        }
        let url = `${site}/api/archive/all`;
        return fetch(url)
            .then(this.handleErrors)
            .then(res => res.json())
            .then(this.PutArchiveToCache);
    }
    static GetArchiveByYear(year: number): Promise<ArchiveYearResult> {
        if (this.archiveResults[year]) {
            return Promise.resolve(this.archiveResults[year]);
        }
        let url = `${site}/api/archive/${year}`;
        return fetch(url)
            .then(this.handleErrors)
            .then(res => res.json())
            .then(this.PutArchiveToCache);
    }

    static GetArchiveYears(): number[] {
        return this.archiveYears;
    }

    static GetArchiveAthlete(id: number): Promise<ArchiveAthlete> {
        if (this.archiveAthletes[id]) {
            return Promise.resolve(this.archiveAthletes[id]);
        }
        let url = `${site}/api/archive/athlete/${id}`;
        return fetch(url)
            .then(this.handleErrors)
            .then(res => res.json())
            .then(this.PutArchiveAthleteToCache);
    }

    static Register(brevetId: number, data: RegistrationData): Promise<string> {
        return fetch(
            new Request(site + '/api/brevet/register/' + brevetId),
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Origin': '*' },
                body: JSON.stringify(data)
            }
        )
            .then(this.handleErrors).then((response) => response.text())
            // store in localStorage succeeded registration
            .then(regId => {
                if (BrevetSvc.localStorageIsAvailable) localStorage.setItem(REG_STORAGE_KEY, JSON.stringify(data));
                this.storeBrevetRegistraion(
                    brevetId,
                    regId,
                    data.cellphone,
                    data.firstname + ' ' + data.lastname.substr(0, 1));
                return regId;
            });
    }

    static SearchBrevetRegistration(brevetId: number, phone: string): Promise<RegistrationInfo | undefined> {
        return fetch(
            new Request(site + '/api/brevet/searchRegistration'),
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Origin': '*' },
                body: JSON.stringify({ BrevetId: brevetId, Phone: phone })
            }
        )
            .then(this.handleErrors).then(response => response.json())
            .then((reg) => {
                if (reg && reg.startNum) {
                    this.storeBrevetRegistraion(brevetId, reg.startNum, reg.phone, reg.name);
                    return { registrationId: reg.startNum, phone: reg.phone, name: reg.name };
                }
                return undefined;
            });
    }

    static GetRegistrationData(): RegistrationData | undefined {
		if (!BrevetSvc.localStorageIsAvailable) return undefined;
        let val = localStorage.getItem(REG_STORAGE_KEY);
        if (val && val.length) return JSON.parse(val) as RegistrationData;
        return undefined;
    }

    static GetRegistrationInfo(brevetId?: number): RegistrationInfo | undefined {
	    if (!BrevetSvc.localStorageIsAvailable) return undefined;
        let val = localStorage.getItem(REG_STORAGE_KEY + brevetId);
        if (val && val.length) return JSON.parse(val) as RegistrationInfo;
        return undefined;
    }

    static startBrevet(id: number, registrationid: string): void {
	    if (BrevetSvc.localStorageIsAvailable) 
			localStorage.setItem(RUN_STORAGE_KEY, JSON.stringify({ brevetId: id, registrationId: registrationid }));
    }
    static stopBrevet(): void {
	    if (!BrevetSvc.localStorageIsAvailable) return;
        localStorage.removeItem(RUN_STORAGE_KEY);
        localStorage.removeItem(RUN_STORAGE_REG_KEY);
        localStorage.removeItem(RUN_STORAGE_PENDING_KEY);
    }

    static completeBrevet(): void {
        let brevetId = this.getStartedBrevetId();
        if (brevetId) {
            let regInfo = this.GetRegistrationInfo(brevetId);
            if (regInfo) {
                regInfo.finished = true;
                if (BrevetSvc.localStorageIsAvailable)
					localStorage.setItem(REG_STORAGE_KEY + brevetId, JSON.stringify(regInfo));
            }
        }
        this.stopBrevet();
    }

    static getStartedBrevetId(): number {
		if (!BrevetSvc.localStorageIsAvailable) return NaN;
        let store = localStorage.getItem(RUN_STORAGE_KEY);
        if (!store) return NaN;
        let stat = JSON.parse(store) as RunningBrevetInfo;
        return stat.brevetId;
    }

    static getStartedBrevetRegistrationId(): string {
	    if (!BrevetSvc.localStorageIsAvailable) return '';
        let store = localStorage.getItem(RUN_STORAGE_KEY);
        if (!store) return '';
        let stat = JSON.parse(store) as RunningBrevetInfo;
        return stat.registrationId;
    }

    static getStartedBrevetResults(): Date[] {
	    if (BrevetSvc.localStorageIsAvailable) {
		    var res = localStorage.getItem(RUN_STORAGE_REG_KEY);
		    if (res) {
			    return JSON.parse(res).map((val: RunningBrevetResult) => {
				    let value = val.manualDate ? val.manualDate : val.date;
				    return value ? new Date(value) : null;
			    });
		    }
	    }
        return [];
    }

    static storeStartedBrevetResult(position: GeolocationPosition, manualDate: Date | undefined): Date[] {
		if (!BrevetSvc.localStorageIsAvailable) return [];
        let res = localStorage.getItem(RUN_STORAGE_REG_KEY);
        let results = res ? JSON.parse(res) as RunningBrevetResult[] : [];
        results.push({
            date: new Date(),
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
            manualDate: manualDate
        });
        localStorage.setItem(RUN_STORAGE_REG_KEY, JSON.stringify(results));
        localStorage.setItem(RUN_STORAGE_PENDING_KEY, '1');
        // initiate sending data
        return BrevetSvc.getStartedBrevetResults();
    }

    static getPendingResults(): boolean {
        return BrevetSvc.localStorageIsAvailable && localStorage.getItem(RUN_STORAGE_PENDING_KEY) != null;
    }

    static getRunningBrevetResults(): Promise<BrevetRunningResult[]> {
        let brevetId = this.getStartedBrevetId();
        if (!brevetId) throw new Error('Started Brevet Id not found');
        return fetch(`${site}/api/brevet/results/${brevetId}`)
            .then(this.handleErrors)
            .then(res => res.json());
    }

    static sendBrevetResult() {
        let res = localStorage.getItem(RUN_STORAGE_REG_KEY);
        if (!res) throw new Error('No stored brevet result');
        let results = JSON.parse(res) as RunningBrevetResult[] || [];
        let data = {
            RegistrationId: BrevetSvc.getStartedBrevetRegistrationId(),
            DateNow: new Date(),
            Results: results.map(r => {
                return {
                    Date: r.date,
                    Latitude: r.latitude,
                    Longitude: r.longitude,
                    Accuracy: r.accuracy,
                    ManualDate: r.manualDate
                };
            })
        };
        localStorage.removeItem(RUN_STORAGE_PENDING_KEY);
        return fetch(
            new Request(site + '/api/brevet/results'),
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Origin': '*' },
                body: JSON.stringify(data)
            }
        )
            .then(this.handleErrors)
            .then(r => r.text())
            .then(r => {
                if (!r.startsWith(results.length + ' ')) throw new Error('Wrong server response');
                else return r;
            })
            .catch(
                error => {
                    localStorage.setItem(RUN_STORAGE_PENDING_KEY, '1');
                    throw error;
                });
    }

    // private members
    private static GetBrevetFromCache(brevetId: number): BrevetInfo | null {
		if (BrevetSvc.localStorageIsAvailable) {
			const item = localStorage.getItem(BREVET_CACHE_STORAGE_KEY + brevetId);
			if (item && item.length) return JSON.parse(item);
		}
        return null;
    }

    private static GetBrevetsFromCache(): BrevetInfo[] | null {
	    if (BrevetSvc.localStorageIsAvailable) {
		    const item = localStorage.getItem(BREVET_CACHE_STORAGE_KEY);
		    if (item && item.length) return JSON.parse(item);
	    }
        return null;
    }

    private static PutBrevetToCache(brevet: BrevetInfo): BrevetInfo {
		if (BrevetSvc.localStorageIsAvailable)
			localStorage.setItem(
				BREVET_CACHE_STORAGE_KEY + brevet.id,
				JSON.stringify({ ...brevet, registrations: null, results: null }) // cache only basic info
			);
        return brevet;
    }

    private static PutArchiveToCache(results: ArchiveYearResult): ArchiveYearResult {
        BrevetSvc.archiveResults[results.year || 0] = results;
        return results;
    }
    private static PutArchiveAthleteToCache(results: ArchiveAthlete): ArchiveAthlete {
        return BrevetSvc.archiveAthletes[results.athleteId] = results;
    }

    private static PutBrevetsToCache(brevets: BrevetInfo[]): BrevetInfo[] {
        localStorage.setItem(
            BREVET_CACHE_STORAGE_KEY,
            JSON.stringify(brevets) // cache only basic info
        );
        if (!this.cacheCleaned) {
            for (const key of Object.keys(localStorage)) {
                if (key.startsWith(REG_STORAGE_KEY) && key.length > REG_STORAGE_KEY.length) {
                    // remove registrations for missing or completed brevets
                    let id = parseInt(key.substring(REG_STORAGE_KEY.length), 10);
                    let brevet = brevets.find((b) => b.id === id);
                    if (!brevet) {
                        localStorage.removeItem(key);
                    }
                } else if (key.startsWith(BREVET_CACHE_STORAGE_KEY) && key.length > BREVET_CACHE_STORAGE_KEY.length) {
                    let id = parseInt(key.substring(BREVET_CACHE_STORAGE_KEY.length), 10);
                    let brevet = brevets.find((b) => b.id === id);
                    if (!brevet) {
                        localStorage.removeItem(key);
                    }
                }
            }
            this.cacheCleaned = true;
        }
        return brevets;
    }

    private static storeBrevetRegistraion(brevetId: number, regId: string, phone: string, name: string): void {
        localStorage.setItem(
            REG_STORAGE_KEY + brevetId,
            JSON.stringify({ registrationId: regId, phone: phone, name: name }));
    }

    private static handleErrors(response: Response): Promise<Response> {
        if (!response.ok) {
            return response.text().then((body) => {
                throw new Error(body && body.length ? body :
                    'Return status: ' + response.status + ' ' + response.statusText);
            });
        }
        return Promise.resolve(response);
    }

    private static setCalculatedProps(brevet: BrevetInfo): BrevetInfo {
        let registrationInfo = BrevetSvc.GetRegistrationInfo(brevet.id);
        if (registrationInfo !== undefined) {
            brevet.registered = true;
            brevet.registration = registrationInfo;
            if (registrationInfo.finished === true) brevet.finished = true;
        }
        brevet.isRunning = !brevet.completed && moment(brevet.date).isBefore(moment());
        return brevet;
    }
}

export interface RegistrationInfo {
    registrationId: string;
    phone: string;
    name: string;
    finished?: boolean;
}

export interface RegistrationData {
    nick: string;
    lastname: string;
    lastnameLat: string;
    firstname: string;
    firstnameLat: string;
    yearOfBirth: string;
    cellphone: string;
    relativesPhone: string;
    city: string;
    orderMedal: boolean;
    bikeType: string;
    club: string;
}

export interface BrevetInfo {
    id: number;
    name: string;
    date: string;
    distance: number;
    city: string;
    completed: boolean;
    finished: boolean;
    detailsUrl?: string;
    imageUrl?: string;
    checkPoints: BrevetInfoCheckPoint[];
    registered: boolean;
    registration?: RegistrationInfo;
    isRunning: boolean;
    registrations?: { nick: string, date: string, money?: number }[];
    results?: BrevetInfoResult[];
}

export interface BrevetInfoCheckPoint {
    name: string;
    longitude: number;
    latitude: number;
    shortName: string;
    description?: string;
    distance?: number;
    openingTime?: string;
    closingTime?: string;
}

export interface BrevetInfoResult {
    lastName: string;
    firstName: string;
    yearOfBirth: number;
    city: string;
    club: string;
    bikeType: string;
    cpTimes: string[];
    totalTime: string;
    orderMedal: boolean;
}

export interface SearchBrevetRegistrationResult {
    startNum: number;
    name: string;
}

export interface RunningBrevetResult {
    date: Date;
    latitude: number;
    longitude: number;
    accuracy: number;
    manualDate?: Date;
}

interface RunningBrevetInfo {
    brevetId: number;
    registrationId: string;
}

export interface BrevetRunningResult {
    name: string;
    count: number;
    results: { nick: string, time: string }[];
}

export interface SeasonBrevetResult {
    result: string | undefined;
    brevetName: string;
    brevetId: number;
}

export interface SeasonResult {
    firstName: string;
    lastName: string;
    year: number;
    cities: string[];
    clubs: string[];
    twos: SeasonBrevetResult[];
    threes: SeasonBrevetResult[];
    fours: SeasonBrevetResult[];
    sixes: SeasonBrevetResult[];
    thousands: SeasonBrevetResult[];
    points: number;
}

export interface ArchiveInfo {
    totalAthletesDistance: number;
    allyears: number[];
    totalBrevetsCount: number;
    totalBrevetsDistance: number;
    totalAthletesCount: number;
    totalAthletsTime: string;
    averageAthleteDistance: number;
    averageAthleteTime: string;
}

export interface ArchiveYearResult {
    year: number;
    brevets: ArchiveBrevet[];
}

export interface ArchiveBrevet {
    brevetId: number;
    name: string;
    date: string;
    distance: number;
    city: string;
    infoUrl?: string;
    cps: string[];
    results: ArchiveBrevetResut[];
}

export interface ArchiveBrevetResut {
    athleteId: number;
    name: string;
    yearOfBirth: number;
    city: string;
    club: string;
    bikeType: string;
    cps: string[];
    totalTime?: string;
}

export interface ArchiveAthlete {
    athleteId: number;
    name: string;
    yearOfBirth: number;
    cities: string;
    clubs: string;
    brevets: ArchiveAthleteBrevet[];
}

export interface ArchiveAthleteBrevet {
    brevetId: number;
    name: string;
    date: string;
    distance: number;
    city: string;
    infoUrl?: string;
    bikeType: string;
    club: string;
    totalTime?: string;
}