import * as React from 'react';
import { ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { Route, Link } from 'react-router-dom';
import { ArchiveRandonner, ArchiveRandonnerResult } from './ArchiveYearView';

interface Props { randonners: ArchiveRandonner[]; }
const InitialState = {
    expanded: -1
};

export default class ArchiveRandonnersList extends React.Component<Props, typeof InitialState> {
    constructor(props: Props) {
        super(props);
        this.state = InitialState;
    }

    toggle(index: number) {
        this.setState({ expanded: this.state.expanded === index ? -1 : index });
    }

    render() {
        return (
            <Route render={({ history }) =>
                <ListGroup>
                    {this.props.randonners && this.props.randonners.map((res, ind) => {
                        const isActive = ind === this.state.expanded;
                        return (<ListGroupItem key={ind} action={true} active={isActive}>
                            <div onClick={this.toggle.bind(this, ind)}>
                                <div className="d-flex justify-content-between">
                                    <ListGroupItemHeading>{res.name}</ListGroupItemHeading>
                                    <ListGroupItemHeading>{res.points}</ListGroupItemHeading>
                                </div>
                                <p>{res.yearOfBirth}г.р. {res.city} {res.club}</p>
                                {isActive &&
                                    <div style={{ display: 'block', width: '100%' }}
                                        className="justify-content-between">
                                        <hr />
                                        {this.links(res.twos, '200', true)}
                                        {this.links(res.threes, '300')}
                                        {this.links(res.fours, '400')}
                                        {this.links(res.sixes, '600')}
                                        {this.links(res.thousands, '1000')}
                                        {this.links(res.thousandTwoHundreds, '1200')}
                                    </div>
                                }
                            </div>
                        </ListGroupItem>
                        );
                    })}
                </ListGroup >}
            />);
    }

    private links(result: ArchiveRandonnerResult[], name: string, first: boolean = false) {
        if (!result || result.length === 0) return null;
        return (
            <div className={first ? '' : 'mt-3'}>
                <h5>{name}</h5>
                {result.map((r, ind) =>
                    <Link key={ind} title={r.brevetName} to={`/archive/${r.year}/${r.brevetId}`}
                        className="d-flex justify-content-between text-light">
                        <span>{new Date(r.date).toLocaleDateString('ru')} {r.brevetName}</span>
                        {r.totalTime || 'DNF'}
                    </Link>
                )}
            </div>
        );
    }
}