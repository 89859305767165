import * as React from 'react';
import { ArchiveBrevet } from '../BrevetSvc';
import { ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

interface Props { brevet: ArchiveBrevet; }

export default class ArchiveBrevetResultList extends React.Component<Props, { expanded: number }> {
    constructor(props: Props) {
        super(props);
        this.state = { expanded: -1 };
    }
    toggle(ind: number) {
        this.setState({ expanded: this.state.expanded === ind ? -1 : ind });
    }

    render() {
        return (
            <ListGroup>
                {this.props.brevet.results && this.props.brevet.results.map((res, ind) => {
                    let isActive = this.state.expanded === ind;
                    return <ListGroupItem key={ind} action={true} active={isActive}>
                        <div onClick={this.toggle.bind(this, ind)}>
                            <div className="d-flex justify-content-between">
                                <ListGroupItemHeading>
                                    <Link className={classNames({ 'text-white': isActive })}
                                        to={`/archive/a/${res.athleteId}`}>
                                        {res.name}</Link></ListGroupItemHeading>
                                <ListGroupItemHeading>{res.totalTime || '---'}</ListGroupItemHeading>
                            </div>
                            <p>{res.yearOfBirth}г.р. {res.city} {res.club} {res.bikeType}</p>
                            {isActive &&
                                <div style={{ display: 'block', width: '100%' }} className="justify-content-between">
                                    <hr />
                                    {res.cps.map((cp, i) => cp ?
                                        <div key={i} className="d-flex justify-content-between">
                                            <small>{this.props.brevet.cps[i]}</small>
                                            {new Date(cp).toLocaleTimeString('ru')}
                                        </div> : null
                                    )}
                                    {res.totalTime && (
                                        <div className="d-flex justify-content-between">
                                            <span>Общее время</span>
                                            <strong>{res.totalTime}</strong>
                                        </div>
                                    )}
                                </div>}
                        </div>
                    </ListGroupItem>;
                }
                )}
            </ListGroup>
        );
    }
}