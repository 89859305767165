import * as React from 'react';
import { Container } from 'reactstrap';
import BrevetSvc, { BrevetInfo } from '../BrevetSvc';
import BrevetsListSection from './BrevetsListSection';
import NetworkError from '../NetworkError';
import LoadingIndicator from '../LoadingIndicator';
import { RouteComponentProps } from 'react-router';
import { Subscription } from 'rxjs/Subscription';
import * as Sentry from '@sentry/browser';
import { SetMenuFunc } from '../WithMenu';

const initialState = {
    current: [] as BrevetInfo[],
    future: [] as BrevetInfo[],
    past: [] as BrevetInfo[],
    isLoading: true,
    error: undefined as string | undefined
};

type State = Readonly<typeof initialState>;

class BrevetsList extends React.Component<RouteComponentProps<{}> & { setMenu: SetMenuFunc }, State> {

    loadObservable: Subscription | undefined;

    // tslint:disable-next-line:no-any
    constructor(props: any) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        document.title = `Бреветы ${process.env.REACT_APP_CLUBNAME || 'КБП'}`;
        this.props.setMenu([{
            onClick: () => this.props.history.push('/season'), name: 'Сводная таблица сезона'
        }, {
            onClick: () => this.props.history.push('/archive'), name: 'Архив'
        }]);
        this.loadObservable = BrevetSvc.GetBrevets()
            .subscribe(
                brevets => {
                    let future: BrevetInfo[] = [];
                    let past: BrevetInfo[] = [];
                    let current: BrevetInfo[] = [];
                    brevets.forEach((brevet: BrevetInfo) => {
                        if (brevet.completed) {
                            past.push(brevet);
                        } else if (brevet.isRunning) {
                            current.push(brevet);
                        } else {
                            future.push(brevet);
                        }
                    });
                    this.setState({ future: future, past: past, current: current });
                },
                error => {
                    Sentry.captureException(error);
                    this.setState({ error: error, isLoading: false });
                },
                () => this.setState({ isLoading: false }) // complete
            );
    }

    componentWillUnmount() {
        if (this.loadObservable) this.loadObservable.unsubscribe();
    }

    render() {
        let notEmpty: boolean = false;
        return (
            <Container>
                {this.state.isLoading && <LoadingIndicator />}
                {this.state.error && <NetworkError error={this.state.error} />}
                {this.state.current && this.state.current.length > 0 && (notEmpty = true) &&
                    <BrevetsListSection title="Идёт сейчас" brevets={this.state.current} history={this.props.history} />
                }
                {this.state.future && this.state.future.length > 0 && (notEmpty = true) &&
                    <BrevetsListSection title="Предстоящие" brevets={this.state.future} history={this.props.history} />
                }
                {this.state.past && this.state.past.length > 0 && (notEmpty = true) &&
                    <BrevetsListSection title="Прошедшие" brevets={this.state.past} history={this.props.history} />
                }
                {!notEmpty &&
                    <h1>Бреветам - быть!</h1>
                }
            </Container>
        );
    }
}

export default BrevetsList;