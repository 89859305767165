import { Control } from "./Control";
export class RadioControl extends Control {
    isOtherSelected: boolean | undefined;
    otherOptions: string[] | null;
    options: string[];
    // tslint:disable-next-line:no-any
    constructor(label: string, placeholder: string, options: string[], isValid: (val: any) => string,
        otherOptions: string[] | null = null) {
        super(label, placeholder, isValid);
        this.options = options;
        this.otherOptions = otherOptions
    }
}
