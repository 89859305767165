import * as React from 'react';
import { ArchiveAthlete } from '../BrevetSvc';
import { ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { Link } from 'react-router-dom';

interface Props { athlete: ArchiveAthlete; }

export default class ArchiveRandonnerBrevetsList extends React.Component<Props, { expanded: number }> {
    constructor(props: Props) {
        super(props);
        this.state = { expanded: -1 };
    }
    toggle(ind: number) {
        this.setState({ expanded: this.state.expanded === ind ? -1 : ind });
    }

    render() {
        return (
            <ListGroup>
                {this.props.athlete.brevets && this.props.athlete.brevets.map((res, ind) => {
                    return <ListGroupItem key={ind} action={true}>
                        <Link to={`/archive/${new Date(res.date).getFullYear()}/${res.brevetId}`}>
                            <div className="d-flex justify-content-between">
                                <ListGroupItemHeading>{res.name}</ListGroupItemHeading>
                                <ListGroupItemHeading>{res.totalTime || '---'}</ListGroupItemHeading>
                            </div>
                            <p>{new Date(res.date).toLocaleDateString('ru')} {res.distance} {res.city} </p>
                        </Link>
                    </ListGroupItem>;
                }
                )}
            </ListGroup>
        );
    }
}