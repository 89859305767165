import * as React from 'react';
import { Table } from 'reactstrap';
import { BrevetInfo } from '../BrevetSvc';

class BrevetsResultTable extends React.Component<{ brevet: BrevetInfo }, {}> {
    render() {
        return (
            <Table hover={true}>
                <thead>
                    <tr>
                        <th>Фамилия</th>
                        <th>Имя</th>
                        <th>Г.р.</th>
                        <th>Город</th>
                        <th>Клуб</th>
                        <th>Вел.</th>
                        <th/>
                        {this.props.brevet.checkPoints &&
                            this.props.brevet.checkPoints.map((cp, i) => (
                                <th key={i}>{cp.shortName}</th>
                            ))}
                        <th>Общее время</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.brevet.results &&
                        this.props.brevet.results.map((res, i) => (
                            <tr key={i}>
                                <td>{res.lastName}</td>
                                <td>{res.firstName}</td>
                                <td>{res.yearOfBirth}</td>
                                <td>{res.city}</td>
                                <td>{res.club}</td>
                                <td>{res.bikeType}</td>
                                <td>{res.orderMedal && <span className="oi oi-badge" title="Заказ медали"
                                    aria-hidden="true" />}</td>
                                {res.cpTimes &&
                                    res.cpTimes.map((cp, ind) => (
                                        <td key={ind}>{cp}</td>
                                    ))}
                                <td>{res.totalTime}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        );
    }
}

export default BrevetsResultTable;