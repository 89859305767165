import React from 'react';
import BrevetSvc, { ArchiveBrevet } from '../BrevetSvc';
import NetworkError from '../NetworkError';
import ViewSelector from '../ViewSelector';
import ArchiveBrevetResultList from './ArchiveBrevetResultList';
import ArchiveBrevetResultTable from './ArchiveBrevetResultTable';

interface Props {
    year: number;
    brevetId: string;
}

const InitialState = {
    error: undefined as string | undefined,
    brevet: undefined as ArchiveBrevet | undefined
};

export default class ArchiveBrevetView extends React.Component<Props, typeof InitialState> {

    constructor(props: Props) {
        super(props);
        this.state = InitialState;
    }

    componentDidMount() {
        const brevetId = parseInt(this.props.brevetId, undefined);
        BrevetSvc.GetArchiveByYear(this.props.year).then(
            result => {
                const brevet = result.brevets.find(b => b.brevetId === brevetId);
                if (brevet) this.setState({ brevet });
            },
            error => {
                this.setState({ error });
            }
        );
    }

    render() {
        if (this.state.error) return <NetworkError error={this.state.error} />;
        if (!this.state.brevet) return null;
        return (
            <div>
                <h1>Бревет {this.state.brevet.name}</h1>
                <p>Дата: {new Date(this.state.brevet.date).toLocaleDateString('ru')}<br />
                    Дистанция: {this.state.brevet.distance} км<br />
                    Город: {this.state.brevet.city}<br />
                    {this.state.brevet.infoUrl &&
                        <a
                            color="link"
                            href={this.state.brevet.infoUrl}
                            target="_blank"
                            rel="noopener noreferrer">Описание</a>}
                </p>
                <h4>Результаты</h4>
                <ViewSelector<{ brevet: ArchiveBrevet}> Component={ArchiveBrevetResultTable}
                    MobileComponent={ArchiveBrevetResultList}
                    brevet={this.state.brevet}
                />
            </div>
        );
    }
}
