import * as React from 'react';
import { Row, Col, Card, CardTitle, CardBody, CardFooter, CardHeader, CardImg, CardSubtitle }
    from 'reactstrap';
import { BrevetInfo } from '../BrevetSvc';
import { Link } from 'react-router-dom';
import moment from 'moment';
import * as H from 'history';

export default class BrevetsListSection extends
    React.Component<{ title: string, brevets: BrevetInfo[], history: H.History }, {}> {
    navigateToBrevet(brevetId: number): void {
        this.props.history.push('/' + brevetId);
    }

    render() {
        return (
            <div style={{ paddingTop: '20px' }}>
                <Row>
                    <Col><h3>{this.props.title}</h3></Col>
                </Row>
                <Row className="row-cols-1 row-cols-lg-3">
                    {this.props.brevets.map((brevet) => (
                        <Col key={brevet.id}><Card
                                style={{ minWidth: '260px', cursor: 'pointer' }}
                                className={(brevet.completed ? 'border-secondary' : 'border-primary') + ' mb-3'}
                                onClick={this.navigateToBrevet.bind(this, brevet.id)}
                            >
                                {brevet.isRunning &&
                                    <CardHeader className="bg-primary text-light">Идёт сейчас</CardHeader>}
                                {brevet.imageUrl &&
                                    <CardImg top={!brevet.isRunning} src={brevet.imageUrl} className="w-100" />}
                                <CardBody>
                                    <CardTitle>{brevet.name}</CardTitle>
                                    <CardSubtitle className="d-flex justify-content-between">
                                        <span>{brevet.distance}км {brevet.city}</span>
                                        {moment(brevet.date).locale('ru').format('LL')}
                                    </CardSubtitle>
                                    {brevet.completed &&
                                        <Link className="btn btn-link mt-3" to={'/' + brevet.id}>Результаты</Link>
                                    }
                                    {!brevet.completed &&
                                        <Link className="btn btn-secondary mt-3" to={'/' + brevet.id}>Открыть</Link>
                                    }
                                </CardBody>
                                {!brevet.completed && brevet.registered && !brevet.finished &&
                                    <CardFooter className="bg-secondary">Зарегистрирован</CardFooter>}
                                {brevet.finished &&
                                    <CardFooter>Пройден</CardFooter>}
                         </Card></Col>
                    ))}
                </Row>
            </div>);
    }
}
