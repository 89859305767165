import * as React from 'react';
import { Container, Row, Col, Progress } from 'reactstrap';

const LoadingIndicator = () => (
    <Container>
        <Row>
            <Col style={{ paddingTop: '20px' }}>
                <Progress animated={true} value={100} >
                Загрузка...
                </Progress>
                </Col>
        </Row>
    </Container>
);

export default LoadingIndicator;