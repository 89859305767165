import { Control } from "./Control";
export class DropDownControl extends Control {
    options: string[];
    // tslint:disable-next-line:no-any
    constructor(label: string, options: string[], isValid: (val: any) => string) {
        super(label, '', isValid);
        this.options = options;
        this.value = options[0];
    }
}
