import BrevetSvc, { ArchiveAthlete } from '../BrevetSvc';
import * as React from 'react';
import LoadingIndicator from '../LoadingIndicator';
import NetworkError from '../NetworkError';
import ViewSelector from '../ViewSelector';
import ArchiveRandonnerBrevetsTable from './ArchiveRandonnerBrevetsTable';
import ArchiveRandonnerBrevetsList from './ArchiveRandonnerBrevetsList';

interface Props {
    athleteId: string;
}

const InitialState = {
    isLoading: true,
    error: undefined as string | undefined,
    athlete: undefined as ArchiveAthlete | undefined
};

export default class ArchiveRandonnerView extends React.Component<Props, typeof InitialState> {
    constructor(props: Props) {
        super(props);
        this.state = InitialState;
    }

    componentDidMount() {
        const athleteId = parseInt(this.props.athleteId, undefined);
        BrevetSvc.GetArchiveAthlete(athleteId).then(
            athlete => {
                this.setState({ athlete, isLoading: false });
            },
            error => {
                this.setState({ error, isLoading: false });
            }
        );
    }

    render() {
        if (this.state.error) return <NetworkError error={this.state.error} />;
        if (this.state.isLoading) return <LoadingIndicator />;
        if (!this.state.athlete) return <NetworkError error="Информации не найдено" />;
        const sums = this.state.athlete.brevets.reduce(
            (prev, brev) => {
                if (brev.totalTime) {
                    const time = brev.totalTime.split(':');
                    if (time.length === 2) {
                        prev.time += parseInt(time[0], 10) * 60 + parseInt(time[1], 10);
                    }
                    prev.distance += brev.distance;
                }
                return prev;
            },
            { time: 0, distance: 0 }
        );
        const totalDays = Math.floor(sums.time / 1440);
        const left = sums.time - totalDays * 1440;
        const hours = Math.floor(left / 60);
        const minutes = left - hours * 60;
        let totalTime = '';
        if (totalDays > 0) totalTime += totalDays === 1 ? ' 1 сутки ' : totalDays + ' cуток ';
        switch (hours) {
            case 21:
            case 1: totalTime += hours + ' час '; break;
            case 2:
            case 22:
            case 23:
            case 3:
            case 4: totalTime += hours + ' часа '; break;
            default: totalTime += hours + ' часов '; break;
        }
        if (minutes === 0) totalTime += ' 0 минут';
        else if (minutes > 10 && minutes < 20) totalTime += minutes + ' минут';
        else if (minutes % 10 === 1) totalTime += minutes + ' минута';
        else if (minutes % 10 >= 2 && minutes % 10 <= 4) totalTime += minutes + ' минуты';
        else totalTime += minutes + ' минут';
        return (
            <div>
                <h1>{this.state.athlete.name}</h1>
                <p>Год рождения: {this.state.athlete.yearOfBirth}<br />
                    Город: {this.state.athlete.cities}<br />
                    Клуб: {this.state.athlete.clubs}<br />
                    Пройдено: {sums.distance} км за {totalTime}
                </p>
                <h4>Бреветы</h4>
                <ViewSelector<{athlete: ArchiveAthlete}> Component={ArchiveRandonnerBrevetsTable}
                    MobileComponent={ArchiveRandonnerBrevetsList}
                    athlete={this.state.athlete}
                />
            </div>
        );

    }
}