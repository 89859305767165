import * as React from 'react';
import {SetMenuFunc} from "../WithMenu";
import {Redirect, RouteComponentProps} from "react-router";
import {Col, Container, Row, Table} from "reactstrap";
import BrevetSvc, {BrevetInfo} from "../BrevetSvc";
import {Subscription} from "rxjs/Subscription";
import LoadingIndicator from "../LoadingIndicator";
import {getMapUrl} from "../Run/Utils";


const initialState = {
    // tslint:disable-next-line:no-any
    error: undefined as any,
    isLoading: true,
    width: window.innerWidth,
    redirectToRun: false,
    brevetId: 0,
    brevet: undefined as BrevetInfo | undefined
};

class Legend extends React.Component<RouteComponentProps<{ id?: string }> & { setMenu: SetMenuFunc }, typeof initialState> {

    constructor(props: any) {
        super(props);
        let brevetId = 0;
        if (this.props.match.params.id) {
            brevetId = parseInt(this.props.match.params.id, undefined);
        }
        this.state = {...initialState, brevetId};
        this.props.setMenu([{
            name: 'Назад',
            onClick: this.goBack.bind(this)
        }])
    }

    private goBack() {
        this.props.history.goBack();
    }

    loader: Subscription | undefined;

    componentDidMount() {
        document.title = 'Легенда';
        if (this.state.brevetId) {
            this.loader = BrevetSvc.GetBrevet(this.state.brevetId).subscribe(
                brevet => {
                    this.setState({brevet});
                    document.title = `Бревет ${brevet.name}`;
                },
                error => this.setState({isLoading: false, error}),
                () => this.setState({isLoading: false}) // completed
            );
        }
    }

    public render() {
        if (this.state.redirectToRun) {
            return <Redirect to="/run" push={true}/>;
        }

        let lastDist = 0;
        return (
            <Container>
                {this.state.isLoading && <LoadingIndicator/>}
                {this.state.brevet &&
                <>
                    <Row>
                        <Col>
                            <h1 className="text-body">Бревет {this.state.brevet.name}</h1>
                            <p>Дата: {new Date(this.state.brevet.date).toLocaleDateString('ru')}<br/>
                                Дистанция: {this.state.brevet.distance} км<br/>
                                Город: {this.state.brevet.city}<br/>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <Table>
                                <thead>
                                <tr>
                                    <th>КП</th>
                                    <th>От пред.</th>
                                    <th>От старта</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.brevet && this.state.brevet.checkPoints && this.state.brevet.checkPoints.map((c, index) => {
                                    const prevDist = (c.distance && index > 1 && (c.distance - lastDist)) || '';
                                    if (c.distance) lastDist = c.distance
                                    return (<tr>
                                        <td>
                                            <a href={getMapUrl(c)}> <strong>{c.name}</strong></a>
                                            {c.description && (<><br/>{c.description}</>)}
                                        </td>
                                        <td>{prevDist}</td>
                                        <td>{index > 0 && c.distance && c.distance > 0 && c.distance}</td>
                                    </tr>);
                                })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </>
                }
            </Container>
        );
    }

}

export default Legend;
