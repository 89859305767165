/* eslint-disable no-extend-native */
import React from 'react';
import Register from './Register';
import BrevetsList from './BrevetsList/BrevetsList';
import BrevetView from './BrevetView/BrevetView';
import Season from './Season/Season';
import LoadingIndicator from './LoadingIndicator';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import 'open-iconic/font/css/open-iconic-bootstrap.css';
import { RouteComponentProps, Redirect, Switch } from 'react-router';
import async from './Async';
import BrevetSvc from './BrevetSvc';
import withMenu from './WithMenu';
import Archive from './Archive/Archive';
import { init } from '@sentry/browser';
import Legend from './BrevetView/Legend';

// include Sentry lib for logging error via sentry
if (process.env.NODE_ENV === 'production') {
  init({
    dsn: 'https://f136df3864784a7faded390091d099c3@o144362.ingest.sentry.io/1186964',
    release: 'kbpkursk-app@' + process.env.npm_package_version
   });
}

const RunLoadable = async<RouteComponentProps<{}>>(
  () => import('./Run/Run'),
  LoadingIndicator
);

// setup moment
moment.locale('ru');

function redirectToRun(Component: React.ComponentClass<RouteComponentProps<{}>>) {
  return (props: RouteComponentProps<{}>) => {
    const brevetStarted = BrevetSvc.getStartedBrevetId();
    return brevetStarted ? <Redirect to="/run" /> : <Component {...props} />;
  };
}

const BrevetsListWithMenu = redirectToRun(withMenu(BrevetsList));
const register = redirectToRun(withMenu(Register));
const BrevetViewWithMenu = redirectToRun(withMenu(BrevetView));
const SeasonWithMenu = redirectToRun(withMenu(Season));
const ArchiveWithMenu = redirectToRun(withMenu(Archive));
const legend = redirectToRun(withMenu(Legend))

const App: React.FunctionComponent<{}> = () => {
  return (
    <Router>
      <Switch>
        <Route path="/run" component={RunLoadable} />
        <Route path="/season" component={SeasonWithMenu} />
        <Route path="/archive" component={ArchiveWithMenu} />
        <Route path="/:id/register" component={register} />
        <Route path="/:id/legend" component={legend} />
        <Route path="/:id" exact={true} strict={true} component={BrevetViewWithMenu} />
        <Route component={BrevetsListWithMenu} />
      </Switch>
    </Router>
  );
};

// Polyfill startsWith
if (!String.prototype.startsWith) {
  Object.defineProperty(String.prototype, 'startsWith', {
    enumerable: false,
    configurable: false,
    writable: false,
    value: function(searchString: string, position?: number) {
      position = position || 0;
      return this.indexOf(searchString, position) === position;
    }
  });
}


// Polyfill Array.find
if (!Array.prototype.find) {
  // tslint:disable-next-line:no-any
  Array.prototype.find = function (predicate: (value: any, index: number, arr: any[]) => boolean) {
    if (this == null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    const list = Object(this);
    // tslint:disable-next-line:no-bitwise
    const length = list.length >>> 0;
    const thisArg = arguments[1];
    let value;

    for (let i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    return undefined;
  };
}

// polyfill string.endsWith
if (!String.prototype.endsWith) {
  Object.defineProperty(String.prototype, 'endsWith', {
    // tslint:disable-next-line:no-any
    value (searchString: any, position: any) {
      const subjectString = this.toString();
      if (position === undefined || position > subjectString.length) {
        position = subjectString.length;
      }
      position -= searchString.length;
      const lastIndex = subjectString.indexOf(searchString, position);
      return lastIndex !== -1 && lastIndex === position;
    }
  });
}

export default App;
