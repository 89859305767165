import * as React from 'react';
import { BrevetInfo } from '../BrevetSvc';
import { ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';

interface Props { brevet: BrevetInfo; }

export default class BrevetResultList extends React.Component<Props, { expanded: number }> {
    /**
     *
     */
    constructor(props: Props) {
        super(props);
        this.state = { expanded: -1 };
    }
    toggle(ind: number) {
        this.setState({ expanded: this.state.expanded === ind ? -1 : ind });
    }

    render() {
        return (
            <ListGroup>
                {this.props.brevet.results && this.props.brevet.results.map((res, ind) => {
                    let isActive = this.state.expanded === ind;
                    return <ListGroupItem key={ind} action={true} active={isActive}>
                        <div onClick={this.toggle.bind(this, ind)}>
                            <div className="d-flex justify-content-between">
                                <ListGroupItemHeading>{res.lastName} {res.firstName} </ListGroupItemHeading>
                                <ListGroupItemHeading>{res.totalTime || '---'}</ListGroupItemHeading>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p>{res.yearOfBirth}г.р. {res.city} {res.club} {res.bikeType}</p>
                                {res.orderMedal &&
                                    <span className="oi oi-badge" title="Заказ медали" aria-hidden="true" />}
                            </div>
                            {isActive &&
                                <div style={{ display: 'block', width: '100%' }} className="justify-content-between">
                                    <hr />
                                    {res.cpTimes.map((cp, i) => cp ?
                                        <div key={i} className="justify-content-between" style={{ display: 'flex' }}>
                                            <small>{this.props.brevet.checkPoints &&
                                                this.props.brevet.checkPoints[i].shortName}</small>
                                            {cp}
                                        </div> : null
                                    )}
                                    {res.totalTime && (
                                        <div className="justify-content-between" style={{ display: 'flex' }}>
                                            <span>Общее время</span>
                                            <strong>{res.totalTime}</strong>
                                        </div>
                                    )}
                                </div>}
                        </div>
                    </ListGroupItem>;
                }
                )}
            </ListGroup>
        );
    }
}