import * as React from 'react';
import { Table } from 'reactstrap';
import { ArchiveBrevet } from '../BrevetSvc';
import { Route } from 'react-router-dom';

const ArchiveBrevetsTable: React.SFC<{ brevets: ArchiveBrevet[] }> = (props) => (

    <Route render={({ history }) =>
        <Table hover={true}>
            <thead>
                <tr>
                    <th>Дата</th>
                    <th>Дистанция</th>
                    <th>Бревет</th>
                    <th>Город</th>
                </tr>
            </thead>
            <tbody>
                {props.brevets.map((res, i) => (
                    <tr key={i} style={{ cursor: 'pointer' }}
                        onClick={() => history.push(
                            `/archive/${new Date(res.date).getFullYear()}/${res.brevetId}`)}>
                        <td>{res.date ? new Date(res.date).toLocaleDateString('ru') : ''}</td>
                        <td>{res.distance}</td>
                        <td>{res.name}</td>
                        <td>{res.city}</td>
                    </tr>
                ))}
            </tbody>
        </Table>} />
);

export default ArchiveBrevetsTable;