import * as React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';
import { SetMenuFunc } from '../WithMenu';
import BrevetSvc, { ArchiveInfo } from '../BrevetSvc';
import LoadingIndicator from '../LoadingIndicator';
import { Container } from 'reactstrap';
import NetworkError from '../NetworkError';
import ArchiveView from './ArchiveView';
import ArchiveYearView from './ArchiveYearView';
import * as Sentry from '@sentry/browser';
import ArchiveBrevetView from './ArchiveBrevetView';
import ArchiveRandonnerView from './ArchiveRandonnerView';

const initialState = {
    allYears: undefined as number[] | undefined,
    isLoading: true,
    error: undefined as string | undefined,
    archiveInfo: undefined as ArchiveInfo | undefined,
};

type State = Readonly<typeof initialState>;
type Props = Readonly<RouteComponentProps<{}> & { setMenu: SetMenuFunc }>;

export default class Archive extends React.Component<Props, State> {
    mounted = false;

    constructor(props: Props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() {
        this.mounted = true;
        BrevetSvc.GetArchive().then(
            result => {
                if (!this.mounted) return;
                this.setState({
                    allYears: result.allyears,
                    archiveInfo: result,
                    isLoading: false
                });
            },
            error => {
                if (!this.mounted) return;
                Sentry.captureException(error);
                this.setState({ error: error, isLoading: false });
            }
        );
    }

    render() {
        return (
            <Container>
                {this.state.isLoading && <LoadingIndicator />}
                {this.state.error && <NetworkError error={this.state.error} />}
                {this.state.archiveInfo && (
                    <Switch>
                        <Route path="/archive/a/:athleteId" render={(route) =>
                            <ArchiveRandonnerView
                                athleteId={route.match.params.athleteId} />
                        } />
                        <Route exact={true} path="/archive/:year(\d+)/:brevetId"
                            render={(route) =>
                                <ArchiveBrevetView
                                    year={parseInt(route.match.params.year)} brevetId={route.match.params.brevetId} />
                            } />
                        <Route path="/archive/:year" component={ArchiveYearView} />
                        <Route // exact={true} path="/archive"
                            render={() => <ArchiveView archive={this.state.archiveInfo!!} />} />
                    </Switch>
                )}
            </Container>
        );
    }
}