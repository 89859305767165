import { RouteComponentProps } from 'react-router';
import { SetMenuFunc } from '../WithMenu';
import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import BrevetSvc, { SeasonResult } from '../BrevetSvc';
import * as Sentry from '@sentry/browser';
import LoadingIndicator from '../LoadingIndicator';
import NetworkError from '../NetworkError';
import SeasonResultTable from './SeasonResultTable';
import SeasonResultList from './SeasonResultList';

const initialState = {
    results: undefined as SeasonResult[] | undefined,
    isLoading: true,
    error: undefined as string | undefined,
    width: window.innerWidth
};

type State = Readonly<typeof initialState>;
type Props = Readonly<RouteComponentProps<{}> & { setMenu: SetMenuFunc }>;

export default class Season extends React.Component<Props, State> {
    mounted = false;

    constructor(props: Props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.mounted = true;
        document.title = `Сводная таблица сезона ${process.env.REACT_APP_CLUBNAME || 'КБП'}`;
        BrevetSvc.GetSeason().then(
            season => {
                if (!this.mounted) return;
                this.setState({ results: season, error: undefined, isLoading: false });
            },
            error => {
                if (!this.mounted) return;
                Sentry.captureException(error);
                this.setState({ error: error, isLoading: false });
            },
        );
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        this.mounted = false;
    }

    handleResize = () => {
        if (!this.mounted) return;
        this.setState({ width: window.innerWidth });
    }

    render() {
        const isMobile = this.state.width < 700;
        return (
            <Container>
                {this.state.isLoading && <LoadingIndicator />}
                {this.state.error && <NetworkError error={this.state.error} />}
                {this.state.results &&
                    <Row><Col style={{ paddingTop: '1rem' }}>
                        <h1>Сводная таблица текущего сезона</h1>
                        {isMobile ? <SeasonResultList results={this.state.results} /> :
                            <SeasonResultTable results={this.state.results} />}
                    </Col></Row>
                }
            </Container>
        );
    }
}
