import * as React from 'react';
import { SeasonResult, SeasonBrevetResult } from '../BrevetSvc';
import { ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { Link } from 'react-router-dom';

const initialState = { expanded: -1 };

interface Props { results: SeasonResult[]; }

export default class SeasonResultList extends React.Component<Props, typeof initialState> {

    constructor(props: Props) {
        super(props);
        this.state = initialState;
    }

    toggle(ind: number) {
        this.setState({ expanded: this.state.expanded === ind ? -1 : ind });
    }

    render() {
        return (
            <ListGroup>
                {this.props.results && this.props.results.map((res, ind) => {
                    let isActive = this.state.expanded === ind;
                    return <ListGroupItem key={ind} action={true} active={isActive}>
                        <div onClick={this.toggle.bind(this, ind)}>
                            <div className="d-flex justify-content-between">
                                <ListGroupItemHeading>{res.lastName} {res.firstName} </ListGroupItemHeading>
                                <ListGroupItemHeading>{res.points}</ListGroupItemHeading>
                            </div>
                            <p>{res.year}г.р. {res.cities && res.cities[0]} {res.clubs && res.clubs[0]}</p>
                            {isActive &&
                                <div>
                                    <hr />
                                    {this.links(res.twos, '200', true)}
                                    {this.links(res.threes, '300')}
                                    {this.links(res.fours, '400')}
                                    {this.links(res.sixes, '600')}
                                    {this.links(res.thousands, '1000+')}
                                </div>}
                        </div>
                    </ListGroupItem>;
                }
                )}
            </ListGroup>
        );
    }

    private links(result: SeasonBrevetResult[], name: string, first: boolean = false) {
        if (!result || result.length === 0) return null;
        return (
            <div className={first ? '' : 'mt-3'}>
                <h5>{name}</h5>
                {result.map((r, ind) =>
                    <Link key={ind} title={r.brevetName} to={`/${r.brevetId}`}
                        className="d-flex justify-content-between text-light">
                        <span>{r.brevetName}</span> {r.result}</Link>
                )}
            </div>
        );
    }

}