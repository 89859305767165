import { Button, Input, Form, FormGroup, Label, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import BrevetSvc, { RegistrationInfo } from '../BrevetSvc';
import * as React from 'react';

interface Props {
    brevetId: number;
    onRegistrationAdded: (registration: RegistrationInfo) => void;
}

const initialState = {
    restoring: false,
    isLoading: false,
    phone: '',
    error: undefined as string | undefined
};

type State = Readonly<typeof initialState>;

export default class RestoreRegistration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = initialState;
    }

    startRestoringRegistration = () => {
        this.setState({ restoring: true });
    }

    cancelRestoring = (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        this.setState({ restoring: false, error: undefined });
    }

    searchBrevetRegistration = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        BrevetSvc.SearchBrevetRegistration(this.props.brevetId, this.extractDigits(this.state.phone))
            .then(reg => {
                if (reg && reg.registrationId) {
                    this.setState({ error: undefined, isLoading: false, restoring: false });
                    this.props.onRegistrationAdded(reg);
                } else {
                    this.setState({ error: 'Регистрация не найдена', isLoading: false });
                }
            })
            .catch(error => this.setState({ error: error, isLoading: false }));
        this.setState({ isLoading: true, error: undefined });
    }

    onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ phone: e.target.value });
    }

    render() {
        if (!this.state.restoring) {
            return (
                <div>
                    <Link className="btn btn-secondary" to={'/' + this.props.brevetId + '/register'}>
                        Зарегистрироваться
                    </Link>
                    <Button color="light" onClick={this.startRestoringRegistration}>
                        Найти регистрацию
                    </Button>
                </div>
            );
        }

        const canSearch = !this.state.isLoading && this.state.phone.length > 9 &&
            this.extractDigits(this.state.phone).length > 9;
        return (
            <div>
                <h3>Поиск регистрации</h3>
                {this.state.error && <Alert color="danger">{this.state.error + ''}</Alert>}
                <Form inline={true} onSubmit={this.searchBrevetRegistration}>
                    <FormGroup>
                        <Label for="phone">Номер телефона:</Label>
                        <Input
                            type="tel"
                            name="phone"
                            placeholder="10 цифр, без 8 или +7"
                            value={this.state.phone}
                            onChange={this.onPhoneChange}
                        />
                        <Button color="primary" type="submit" disabled={!canSearch}>Найти</Button>
                        <Button
                            color="light"
                            type="reset"
                            onClick={this.cancelRestoring}
                            disabled={this.state.isLoading}
                        >
                            Отменить
                        </Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }

    private extractDigits(s: string) {
        return s.split('').filter(c => c >= '0' && c <= '9').join('');
    }
}