import { RouteComponentProps } from 'react-router';
import * as React from 'react';
import { Navbar, NavbarToggler, Nav, NavItem, NavLink, Collapse, NavbarBrand } from 'reactstrap';

type menuItem = { onClick: () => void, name: string };
export type SetMenuFunc = (links: menuItem[]) => void;

export default function withMenu(Component: React.ComponentClass<RouteComponentProps<{}> & { setMenu: SetMenuFunc }>) {
  const initialState = {
    menus: [] as menuItem[],
    isOpen: false
  };

  type State = Readonly<typeof initialState>;
  return class ComponentWithMenu extends React.Component<RouteComponentProps<{}>, State> {
    constructor(props: RouteComponentProps<{}>) {
      super(props);
      this.state = initialState;
    }

    setMenu = (menus: menuItem[]) => {
      this.setState({ menus });
    }

    toggle = () => {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    render() {
      return (
        <div>
          <Navbar color={process.env.REACT_APP_NAVBAR_COLOR} light={!process.env.REACT_APP_NAVBAR_DARK}
                  dark={!!process.env.REACT_APP_NAVBAR_DARK} expand="md" container="lg">
              <NavbarBrand className="me-auto" href="/">
                <img alt="" src="/navbaricon.png" className="d-inline-block align-middle me-3" />
                {process.env.REACT_APP_NAVBAR_TEXT}
              </NavbarBrand>
              {this.state.menus.length > 0 &&
                <>
                  <NavbarToggler onClick={this.toggle} />
                  <Collapse isOpen={this.state.isOpen} navbar={true}>
                    <Nav className="ms-auto" navbar={true}>
                      {this.state.menus.map(m => (
                        <NavItem key={m.name}>
                          <NavLink onClick={m.onClick} href='#'>
                            {m.name}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </Collapse>
                </>
              }
          </Navbar>
          <Component setMenu={this.setMenu} {...this.props} />
        </div>
      );
    }
  };
}