import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Container, Row, Col, Alert, Button } from 'reactstrap';
import LoadingIndicator from '../LoadingIndicator';
import BrevetSvc, { BrevetInfo, RegistrationInfo } from '../BrevetSvc';
import NetworkError from '../NetworkError';
import { Redirect } from 'react-router-dom';
import BrevetResultTable from './BrevetResultTable';
import BrevetResultList from './BrevetResultList';
import RestoreRegistration from './RestoreRegistration';
import { Subscription } from 'rxjs/Subscription';
import { SetMenuFunc } from '../WithMenu';
import { Link } from 'react-router-dom';

const initialState = {
    // tslint:disable-next-line:no-any
    error: undefined as any,
    isLoading: true,
    width: window.innerWidth,
    redirectToRun: false,
    brevetId: 0,
    brevet: undefined as BrevetInfo | undefined,
    restoringRegistration: false
};

class BrevetsView extends React.Component<
    RouteComponentProps<{ id?: string }> & { setMenu: SetMenuFunc },
    typeof initialState> {

    loader: Subscription | undefined;
    // tslint:disable-next-line:no-any
    constructor(props: any) {
        super(props);
        let brevetId = 0;
        if (this.props.match.params.id) {
            brevetId = parseInt(this.props.match.params.id, undefined);
        }
        this.state = { ...initialState, brevetId };
    }

    onStartClick = () => {
        if (!this.state.brevet) throw new Error('brevet is not set');
        if (!this.state.brevet.registration) throw new Error('brevet registration not set');
        BrevetSvc.startBrevet(this.state.brevet.id, this.state.brevet.registration.registrationId);
        this.setState({ redirectToRun: true });
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        document.title = 'Бревет';
        if (this.state.brevetId) {
            this.loader = BrevetSvc.GetBrevet(this.state.brevetId).subscribe(
                brevet => {
                    this.setState({ brevet });
                    document.title = `Бревет ${brevet.name}`;
                    this.showMenu(brevet);
                },
                error => this.setState({ isLoading: false, error }),
                () => this.setState({ isLoading: false }) // completed
            );
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        if (this.loader) this.loader.unsubscribe();
    }

    handleResize = () => {
        this.setState({ width: window.innerWidth });
    }

    showMenu(brevet: BrevetInfo) {
        if (brevet.registered && !brevet.isRunning) {
            this.props.setMenu([{
                onClick: this.onStartClick, name: brevet.finished ? 'Стартовать заново'
                    : 'Стартовать бревет'
            }]);
        }
    }

    onRegistrationFound = (registration: RegistrationInfo) => {
        const brevet = this.state.brevet;
        if (brevet) {
            brevet.registered = true;
            brevet.registration = registration;
            this.setState({ brevet });
            this.showMenu(brevet);
        }
    }

    render() {
        if (this.state.redirectToRun) {
            return <Redirect to="/run" push={true} />;
        }

        const isMobile = this.state.width < 700;

        return (
            <div>
                <Container className="py-3">
                    {this.state.brevet && (
                        <>
                        <Row>
                            <Col>
                                <h1>Бревет {this.state.brevet.name}</h1>
                                <p>Дата: {new Date(this.state.brevet.date).toLocaleDateString('ru')}<br />
                                    Дистанция: {this.state.brevet.distance} км<br />
                                    Город: {this.state.brevet.city}<br />
                                </p>
                            </Col>
                        </Row>
                        <Row><Col sm={8} md={6} xl={4} className="mb-3">
                            {this.state.brevet.detailsUrl &&
                            <a
                                className="btn btn-light"
                                href={this.state.brevet.detailsUrl} target="_blank"
                                rel="noopener noreferrer"
                            >Описание</a>}
                            <Link className="btn btn-light" to={`/${this.state.brevetId}/legend`}>Легенда</Link>
                        </Col></Row>
                        <Row>
                            <Col>
                                {!this.state.isLoading && !this.state.brevet.completed &&
                                !this.state.brevet.registered &&
                                !this.state.brevet.finished && (
                                    <RestoreRegistration
                                        brevetId={this.state.brevet.id}
                                        onRegistrationAdded={this.onRegistrationFound}
                                    />
                                )}
                                {!this.state.brevet.completed && this.state.brevet.registered &&
                                    !this.state.brevet.finished &&
                                    <Alert color="warning">
                                        Вы зарегистрированы на бревет. Cтартовый номер:&nbsp;
                                        {this.state.brevet.registration &&
                                            this.state.brevet.registration.registrationId +
                                            ` (${this.state.brevet.registration.name})`}
                                    </Alert>
                                }
                                {this.state.brevet.finished &&
                                    <Alert color="success" className="d-flex justify-content-between">Бревет пройден
                                    </Alert>}
                                {this.state.brevet.isRunning && this.state.brevet.registered &&
                                    !this.state.brevet.finished &&
                                    <div>
                                        <Button color="primary" onClick={this.onStartClick}>Начать бревет</Button>
                                    </div>
                                }
                            </Col>
                        </Row>
                        </>
                    )}
                    {this.state.isLoading && <LoadingIndicator />}
                    {this.state.error && <NetworkError error={this.state.error} />}
                    {this.state.brevet && new Date(this.state.brevet.date) < new Date() && (
                        <Row><Col style={{ paddingTop: '1rem' }}>
                            <h4>Результаты</h4>
                            {isMobile ? <BrevetResultList brevet={this.state.brevet} /> :
                                <BrevetResultTable brevet={this.state.brevet} />}
                        </Col></Row>
                    )}
                    {this.state.brevet && !this.state.brevet.completed && (
                        <Row><Col sm={8} md={6} xl={4} className="mt-3">
                            {this.state.brevet.registrations &&
                                this.state.brevet.registrations.length > 0 &&
                                <div>
                                    <h4>Зарегистрированы
                                        <small className="fs-6 ps-3">{this.state.brevet.registrations.length}</small>
                                    </h4>
                                    {this.state.brevet.registrations.map(reg => (
                                        <Row key={reg.date}>
                                            <Col xs={reg.money ? 5 : 6}>{reg.nick}</Col>
                                            {reg.money &&
                                                <Col className="text-muted text-end" xs={1}>{reg.money}</Col>}
                                            <Col xs={4}><small className="text-nowrap">
                                                {new Date(reg.date).toLocaleString('ru')}</small></Col>
                                        </Row>
                                    ))}
                                </div>
                            }
                            {!this.state.brevet.registrations?.length &&
                                <p>Зарегистрированных пока нет</p>}
                        </Col></Row>
                    )}
                </Container>
            </div>
        );
    }
}

export default BrevetsView;