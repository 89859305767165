import * as React from 'react';
import { ArchiveInfo } from '../BrevetSvc';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

interface Props {
    archive: ArchiveInfo;
}

const ArchiveView: React.SFC<Props> = (props) => {
    return (
        <div>
            <Row><Col>
                <h1>Aрхив Бреветов</h1>
                <h3>Годы:</h3>
                <div>
                    <Link className="d-inline-block p-3" to={`/archive/all`}>Все</Link>
                    {props.archive.allyears.map(year =>
                        <Link className="d-inline-block p-3" key={year} to={`/archive/${year}`}>{year}</Link>)}
                </div>
            </Col></Row>
            <Row>
                <Col>
                    <i>Всего бреветов проведено:</i> {props.archive.totalBrevetsCount +
                        ' (' + props.archive.totalBrevetsDistance} км)<br />
                    <i>Участвовало:</i> {props.archive.totalAthletesCount} рандоннеров<br />
                    <i>Пройдено:</i> {props.archive.totalAthletesDistance} км
                    ({Math.floor(props.archive.totalAthletesDistance / 4007.5) / 10} экваторов земли,
                    {' ' + Math.floor(props.archive.totalAthletesDistance / 38440) / 10} расстояния до луны
                    ) <br />
                    <i>Проведено в седле:</i> {props.archive.totalAthletsTime} <br />
                    <i>Средняя дистанция пройденного брeвета:</i> {props.archive.averageAthleteDistance} км<br />
                    <i>Среднее время пройденного бревета:</i> {props.archive.averageAthleteTime}
                </Col>
            </Row>
        </div>
    );
};

export default ArchiveView;