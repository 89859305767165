import * as React from 'react';

type component<T> = React.StatelessComponent<T> | React.ComponentClass<T>;

interface Props<T> {
    MobileComponent: component<T>;
    Component: component<T>;
}

export default class ViewSelector<T> extends React.Component<Props<T> & T, {width: number}> {
    mounted = false;

    constructor(props: Props<T> & T) {
        super(props);
        this.state = {width:  window.innerWidth};
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        this.mounted = false;
    }

    handleResize = () => {
        if (!this.mounted) return;
        this.setState({ width: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.mounted = true;
    }

    render() {
        const isMobile = this.state.width < 700;
        let Comp: component<T> =
            isMobile ? this.props.MobileComponent : this.props.Component;
        // const { Component, MobileComponent, ...otherProps} = this.props;
        return <Comp {...this.props} />;
    }
}